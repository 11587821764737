import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import BuenoManoPromo from 'images/bueno-mano.jpg'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/buenomanotopup`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'BUENO MANO TOP-UP PROMO (September 15-30, 2022)',
  },
  {
    property: 'og:description',
    content:
      'Get additional Digipay Credits if you top up at least P1,500 and transact at least P1,000',
  },
  {
    property: 'og:image',
    content: BuenoManoPromo,
  },
]

const Mechanics = () => {
  return (
    <>
      <h4>MECHANICS</h4>
      <div className='mechanics lead'>
        <ul>
          <li> This promo is open to all newly-activated Digipay Agent. </li>
          <li>
            To join, newly-activated Digipay Agent should do the following to
            get the P100 free top-up credits during the promo duration:
            <ul>
              <li> 1st top-up should be a minimum of P1,500 and; </li>
              <li> Accumulate transactions with the total amount of P1,000 </li>
            </ul>
          </li>
          <li> This promo will run from September 15 to 30, 2022 only. </li>
          <li>
            Newly-activated Digipay Agent is entitled to only one free P100
            top-up credit.
          </li>
          <li>
            Free P100 top-up credits are non-transferrable and cannot be
            converted to cash.
          </li>
          <li>
            Qualified agents will be notified by Digipay via call and email
            notifications.
          </li>
          <li>
            Employees of Digipay and of its affiliates, including relatives up
            to the second degree of consanguinity or affinity, are disqualified
            from this promo.
          </li>
          <li> Per DTI Fair Trade Permit No FTEB-151342 Series of 2022. </li>
        </ul>
      </div>
    </>
  )
}

const Promo4 = () => (
  <div>
    <Helmet title="Promos | BUENO MANO TOP-UP PROMO" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold">
        BUENO MANO TOP-UP PROMO
      </h1>
      <Image
        src={BuenoManoPromo}
        width="100%"
        align="center"
        className="promo-image"
      />
      <Mechanics />
    </div>
    <Footer />
  </div>
)
export default Promo4
